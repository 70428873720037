<template>
  <Layout
    @click-left="goBack"
    @click-right="openPopup"
    :title="$route.query.nickname || ''"
  >
    <template #right>
      <van-icon name="records" size="20px" />
    </template>
    <div class="user_detail_wrap">
      <div class="avatar_wrap">
        <i>
          <img :src="detailData.avatar" alt="" />
        </i>

        <div class="user_info">
          <div>
            <h3 @click="execCopy(code)">
              {{ code }}
              <i><img src="@/assets/images/copy.png" alt=""
              /></i>
            </h3>
            <p>
              {{ editTime(detailData.last_live_enter_time) }} 本月{{
                detailData.enter_count
              }}次 送礼{{ detailData.gitf_count }}
            </p>
            <!-- <h3>{{ detailData.nickname }}</h3> -->
          </div>
          <div>
            {{ detailData.province_name || "--" }}
            <br />
            {{ detailData.city_name || "--" }}
          </div>
        </div>
      </div>

      <div class="intention_wrap">
        <div>
          <p>意<br />向</p>
        </div>
        <div class="intention_content">
          <div
            class="intention_item"
            v-for="(item, index) in seriesList"
            :key="index"
          >
            <p>{{ item.name }}</p>
            <p>({{ item.count || 0 }})</p>
            <i>
              <img :src="item.avatar" alt="" />
            </i>
          </div>
        </div>
      </div>

      <div class="word_recommen">
        <div class="word_recommen_header">
          <b>话术推荐：</b>
          <span v-if="$route.query.visit_batch"
            >最近回访: {{ $route.query.visit_batch }}</span
          >
        </div>
        <div class="word_recommen_content">
          <textarea
            name="word_recommen"
            id=""
            cols="30"
            rows="5"
            v-model="content"
          ></textarea>
          <div>
            <button @click="handleCopy">复制</button>
          </div>
        </div>
      </div>

      <!-- <div class="platform_detail">
        <div class="platform_code">
          <h3>
            {{ code }}
            <i @click="execCopy(code)"
              ><img src="@/assets/images/copy.png" alt=""
            /></i>
          </h3>
          <p>
            {{ editTime(detailData.last_visit_time) }} 本月{{
              detailData.support_times
            }}次 送礼{{ detailData.gifts_times }}
          </p>
        </div>
      </div> -->
      <div class="comment_title">
        <h3>历史价值互动：</h3>
      </div>
      <div class="comment_wrap">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="comment_item"
            v-for="(item, index) in commentList"
            :key="index"
          >
            <h3>{{ item.publish_time }}</h3>
            <p>{{ item.content }}</p>
          </div>
        </van-list>
      </div>

      <!-- <div class="word_wrap">
      <h3>话术推荐：</h3>
      <div class="word_content">
        <div class="word_item_outer" v-for="(item, index) in wordList" :key="index">
          <div class="word_item">
            <p>{{item.question}}</p>
            <div class="word_box">
              <div>
                {{item.answer}}
              </div>
              <a @click="execCopy(item.answer)">
                <i>
                  <img src="@/assets/images/copy-blue.png" alt="" />
                </i>
                <span> 复制 </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>

    <van-popup
      v-model="popupShow"
      position="right"
      :style="{ width: '80%', height: '100%' }"
    >
      <div class="user_list_tools">
        <h3>加追踪</h3>
        <div class="label">意向度:</div>
        <div>
          <van-button
            v-for="(item, idx) in intentionList"
            :key="idx"
            style="margin-right: 5px"
            :class="selectIntention === item ? 'intention_selected' : ''"
            @click="handleIntentionItemClick(item)"
            >{{ item }}</van-button
          >
        </div>
        <div class="input_wrap">
          <input v-model="intentionWord" placeholder="请输入" />
        </div>
        <div class="label">城市(单选):</div>
        <el-select
          v-model="province_code"
          placeholder="请选择省份"
          @change="provChange"
          style="width: 50%"
        >
          <el-option
            v-for="t in provinceList"
            :key="t.id"
            :label="t.name"
            :value="t.region_code"
          ></el-option>
        </el-select>
        <el-select
          v-model="city_code"
          placeholder="请选择城市"
          style="width: 50%"
        >
          <el-option
            v-for="t in cityList"
            :key="t.id"
            :label="t.name"
            :value="t.region_code"
          ></el-option>
        </el-select>
        <div class="label">车型(多选):</div>
        <el-select
          v-model="series"
          multiple
          filterable
          placeholder="车型"
          style="width: 100%"
        >
          <el-option
            v-for="item in seriesOptions"
            :key="item.seriesId"
            :label="item.seriesName"
            :value="item.seriesId"
          >
          </el-option>
        </el-select>
        <div style="padding-top: 30px; text-align: right">
          <van-button type="info" size="small" @click="handleAddClick"
            >确定添加</van-button
          >
        </div>
      </div>
    </van-popup>

    <van-dialog
      v-model="isDialogShow"
      title="此用户已【私信回访】？"
      show-cancel-button
      :before-close="handleClose"
    >
      <div style="padding: 20px 0; display: flex; justify-content: center">
        <van-radio-group v-model="isDefaultCopy" @change="handleChange">
          <van-radio
            name="1"
            shape="square"
            icon-size="16px"
            @click="handleRadioClick"
            >复制话术默认回访</van-radio
          >
        </van-radio-group>
      </div>
    </van-dialog>
  </Layout>
</template>

<script>
import commonApi from "@/api/common.js";
import moment from "moment";
import eventBus from "@/utils/goBackEntity.js";
import Layout from "../layout";
import cookie from "js-cookie";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      imgHref: process.env.VUE_APP_OSS_HOST,
      finished: false,
      loading: false,
      wordList: [
        {
          question: "打算最近购车/到店看过/价格合适/怎么到店/想去试驾/现车",
          answer:
            "您好，在我的主页上有店的地址和导航，您可以通过私信留下您的联系方式，然后我帮您安排专业的销售顾问接待您，安排试乘试驾服务，到店试驾还会有额外的礼品赠送哦。",
        },
        {
          question: "落地多少钱/优惠多少/分期/有什么东西送/置换",
          answer:
            "您好，您在直播间关注的xx车型最近有活动促销，因为私信发方案会被限制，您可以留下您的联系方式给我加您，然后发一份详细的方案给您参考，预约到店看车还有额外的专属礼包。",
        },
        {
          question: "配置/空间/动力/油耗/对比咨询",
          answer:
            "您好，两款车子各有各的优势，XX车型空间大，外观时尚，安全配置高，操控性好，外观运动；您可以留下您的联系方式，我们帮您预约到店看车试驾对比一下。",
        },
        {
          question: "看看/xx车型怎么样",
          answer:
            "您好，我们店有xx车型的，您可以留下您的联系方式，我帮您安排专业的销售顾问给您介绍。",
        },
      ],
      detailData: {},
      seriesList: [],
      seriesListName: '',
      commentList: [],
      pageNo: 1,
      pageSize: 10,
      code: this.$route.query.code,
      popupShow: false,
      intentionList: ["H", "A", "B", "C", "F"],
      selectIntention: "",
      intentionWord: "",
      seriesOptions: [],
      series: [],
      province_code: "",
      city_code: "",
      provinceList: [],
      cityList: [],
      isDialogShow: false,
      isDefaultCopy: "",
      isDefaultCopyChange: false,
      needDialog: true,
      content: "",
    };
  },
  watch: {
    seriesList(val) {
      console.log(3,val)
      this.seriesListName = val.map(i => {return i.series_name}).join(',')
      this.content = `你好，你在直播间关注的【${this.seriesListName || '意向车型变量'}】最近有活动促销；因为私信发方案会被限制，可以留下你的联系方式，我们会尽快安排专人联系你，预约到店看车还有额外的专属礼包`
    }
  },
  mounted() {

  },
  created() {
    commonApi
      .getUsgetPollingInfoerList({
        type: this.$route.query.type == 'douyin' ? 1 : 2,
        fans_id: this.$route.query.fans_id,
        author_id: this.$route.query.author_id,
      })
      .then(({ data }) => {
        this.detailData = data.data;
      });
    commonApi
      .getIntention({
        type: this.$route.query.type == 'douyin' ? 1 : 2,
        fans_id: this.$route.query.fans_id,
        author_id: this.$route.query.author_id,
        comment_validate: 1
        // series_ids: this.$route.query.series_ids
        //   ? JSON.stringify(this.$route.query.series_ids.split(","))
        //   : "[]",
      })
      .then(({ data }) => {
        this.seriesList = data.data.series;
      });
    commonApi
      .getSeriesList({
        author_id: this.$route.query.author_id,
        origin: this.$route.query.type,
      })
      .then(({ data }) => {
        let newArr = []
        for (let key in data.data) {
          newArr.push({
            seriesId: key,
            seriesName: data.data[key]
          })
        }
        console.log(213, newArr)
        this.seriesOptions = newArr;
      });
    // 初始化地址信息
    commonApi
      .getBuyCarListProps({
        parent_id: 0,
        level: 1,
      })
      .then(({ data }) => {
        this.provinceList = data.data;
        let { province_code, city_code } = this.$route.query;
        if (province_code && city_code) {
          this.province_code = +province_code;
          return commonApi
            .getBuyCarListProps({
              parent_id: province_code,
              level: 2,
            })
            .then(({ data: citys }) => {
              console.log(citys)
              this.cityList = citys;
              this.city_code = +city_code;
            });
        }
      });
    // 主播最近一次回访话术
    commonApi
      .getLastWord({ author_id: this.$route.query.author_id })
      .then(({ data = {} }) => {
        let content = data.content || `你好，你在直播间关注的【意向车型变量】最近有活动促销；因为私信发方案会被限制，可以留下你的联系方式，我们会尽快安排专人联系你，预约到店看车还有额外的专属礼包`;
        this.content = content
      });
  },
  methods: {
    handleCopy() {
      let isDefaultCopy = cookie.get("isDefaultCopy");
      if (isDefaultCopy) {
        // 提交记录
        this.getReturnVisit();
      } else {
        this.isDialogShow = true;
      }
    },
    handleChange() {
      this.isDefaultCopyChange = true;
    },
    handleRadioClick() {
      if (!this.isDefaultCopyChange) {
        this.isDefaultCopy = "";
      }
      this.isDefaultCopyChange = false;
    },
    handleClose(action, done) {
      if (action === "confirm") {
        if (this.isDefaultCopy == 1) {
          cookie.set("isDefaultCopy", "1");
        }
        // 提交记录
        this.getReturnVisit().then(() => {
          done();
        });
      } else {
        done();
      }
    },
    getReturnVisit() {
      const author_id = this.$route.query.author_id;
      const content = this.content;
      const fans_id = this.$route.query.fans_id;
      this.execCopy(content)
      return commonApi.getReturnVisit({
        author_id,
        content,
        fans_id,
        user_id: "2333333",
      })
    },
    onLoad() {
      commonApi
        .getPollingV({
          type: this.$route.query.type == 'douyin' ? 1 : 2,
          comment_validate: 1,
          fans_id: this.$route.query.fans_id,
          author_id: this.$route.query.author_id,
          current: this.pageNo,
          page_size: this.pageSize,
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.list.length == 0) {
            this.finished = true;
            return false;
          }
          this.pageNo += 1;
          this.commentList = this.commentList.concat(data.list);
        });
    },
    openPopup() {
      this.popupShow = true;
    },
    // 处理时间差
    editTime(day) {
      if (moment().diff(day, "days") < 1) {
        return "不久前来过";
      } else {
        return `${moment().diff(day, "days")}天前来过`;
      }
    },
    execCopy(text) {
      return this.$copyText(text).then(()=> {
        this.$toast.success('复制成功')
      }).catch((err)=> {
        alert(JSON.stringify(err))
        this.$toast.fail("当前系统无法复制，请联系管理员")
      })

    },

    goBack() {
      eventBus.$emit("id", this.$route.query.id);
      this.$router.back();
    },
    handleAddClick() {
      let intention = "";
      if (!this.selectIntention && !this.intentionWord) {
        this.$toast.fail("请输入或选择意向度");
        return;
      } else {
        intention = this.intentionWord || this.selectIntention;
      }
      if (this.series.length === 0) {
        this.$toast.fail("请选择车型");
        return;
      }
      if (!this.province_code || !this.city_code) {
        this.$toast.fail("请选择城市");
        return;
      }
      let params = {
        author_id: this.$route.query.author_id,
        customer_platform_id: this.$route.query.fans_id,
        nickname: this.detailData.nickname,
        gender: this.detailData.gender,
        avatar_url: this.detailData.avatar || "",
        favorite_level: intention,
        province_code: this.province_code || null,
        city_code: this.city_code || null,
        origin: this.$route.query.type == "douyin" ? 1 : 2,
        series_str: this.series.join(","),
        code: this.$route.query.code || "",
      };
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      commonApi.addIntention(params).then(({ data }) => {
        if (data.errcode) {
          this.$toast.fail(data.errmsg || "添加潜客失败");
          return;
        }
        this.$toast.success("添加潜客成功");
        this.popupShow = false;
      });
    },
    handleIntentionItemClick(item) {
      this.selectIntention = item;
    },
    provChange(val) {
      this.getCity(val);
    },
    getCity(id) {
      return commonApi
        .getBuyCarListProps({
          parent_id: id,
          level: 2,
        })
        .then(({ data }) => {
          this.cityList = data.data;
          this.city_code = data.data[0].region_code;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.user_detail_wrap {
  padding-top: 10px;
  height: 100vh;
  overflow-y: scroll;
}
.back_warp {
  padding-left: 17px;
  position: relative;
  top: -10px;
}
.avatar_wrap {
  position: relative;
  padding-left: 77px;
  > i {
    position: absolute;
    top: 0;
    left: 16px;
    display: block;
    width: 44px;
    height: 44px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  > .user_info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c1c0c0;
    padding: 0 30px 5px 8px;

    > div {
      &:first-child {
        flex: 1;
        > h3 {
          font-size: 16px;
          color: #5579e2;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          > i {
            position: relative;
            top: 5px;
            display: inline-block;
            width: 24px;
            height: 24px;
            overflow: hidden;
            cursor: pointer;
            margin-left: 16px;
          }
        }
        > p {
          font-size: 10px;
          color: #3a3a3a;
        }
      }
      &:last-child {
        padding-top: 10px;
        width: 50px;
        font-size: 10px;
      }
    }
  }
}

.intention_wrap {
  position: relative;
  margin-top: 25px;
  padding: 0 0 0 72px;
  > div {
    &:first-child {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      > p {
        height: 40px;
        width: 53px;
        border-right: 1px solid #979797;
        font-size: 14px;
        font-weight: 500;
        color: #9b9b9b;
        text-align: center;
      }
    }
  }
  .intention_content {
    height: 55px;
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    > .intention_item {
      display: inline-block;
      position: relative;
      width: 134px;
      height: 55px;
      margin-right: 8px;
      padding-right: 70px;
      padding-top: 3px;
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid #979797;
      overflow: hidden;
      > p {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
        color: #585959;
        line-height: 22px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        white-space: nowrap;
      }
      > i {
        position: absolute;
        z-index: 0;
        top: 0;
        right: -15px;
        display: block;
        width: 75px;
        height: 53px;
        overflow: hidden;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.platform_detail {
  padding: 10px 20px 0;
  .platform_code {
    padding-bottom: 12px;
    padding-left: 5px;
    border-bottom: 1px solid #edeff4;
    > h3 {
      font-size: 18px;
      font-weight: 600;
      color: #4a4a4a;
      margin-bottom: 8px;
      > i {
        position: relative;
        top: 5px;
        display: inline-block;
        width: 24px;
        height: 24px;
        overflow: hidden;
        cursor: pointer;
        margin-left: 16px;
      }
    }
    > p {
      font-size: 12px;
      color: #9b9b9b;
      line-height: 17px;
      font-weight: 500;
    }
  }
}
.comment_title {
  padding: 0 20px;
  margin-bottom: 10px;
  > h3 {
    font-size: 16px;
    color: #3a3a3a;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
}
.comment_wrap {
  height: 30vh;
  overflow-y: auto;
  padding: 0px 20px;
  margin: 10px 0;
  .comment_item {
    padding-bottom: 24px;
    padding-left: 5px;
    > h3 {
      font-size: 14px;
      font-weight: 500;
      color: #a29f9f;
      margin-bottom: 8px;
    }
    > p {
      font-size: 14px;
      font-weight: 500;
      color: #4a4a4a;
      line-height: 20px;
    }
  }
}

.word_wrap {
  padding-bottom: 20px;
  > h3 {
    font-size: 14px;
    font-weight: 500;
    color: #4e4b4b;
    padding-bottom: 5px;
    padding-left: 16px;
  }
  > .word_content {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 230px;
    padding-left: 16px;
    display: flex;
    align-items: flex-start;

    .word_item_outer {
      &:not(:first-child) {
        padding-left: 12px;
      }
      &:not(:last-child) {
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 1px;
          height: 220px;
          background: #edeff4;
          content: " ";
        }
      }
      display: inline-block;
      position: relative;
      padding-right: 12px;
      // &::after {
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   display: block;
      //   width: 1px;
      //   height: 220px;
      //   background: #edeff4;
      //   content: " ";
      // }
    }
    .word_item {
      width: 163px;
      height: 230px;
      white-space: normal;
      > p {
        font-size: 12px;
        height: 42px;
        font-weight: 500;
        color: #6f6d6d;
        line-height: 17px;
        padding-bottom: 8px;
        text-align: justify;
      }
      > .word_box {
        width: 163px;
        height: 187px;
        background: #edeff4;
        border-radius: 4px;
        padding: 16px 12px 10px;
        > div {
          width: 139px;
          height: 136px;
          overflow-y: scroll;
          font-size: 12px;
          font-weight: 500;
          color: #4a4a4a;
          line-height: 17px;
          text-align: justify;
        }
        > a {
          position: absolute;
          right: 70px;
          bottom: 5px;
          display: block;
          font-size: 12px;
          font-weight: 500;
          color: #5579e2;
          text-align: center;
          > i {
            display: inline-block;
            width: 24px;
            height: 24px;
            overflow: hidden;
          }
          > span {
            position: relative;
            top: -7px;
          }
        }
      }
    }
  }
}
.word_recommen {
  padding: 20px 25px;
  .word_recommen_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    > span {
      color: #ee0a24;
      font-size: 10px;
    }
  }
  .word_recommen_content {
    border: 1px solid #ddd;
    padding: 10px;
    > textarea {
      display: block;
      margin-bottom: 5px;
      width: 100%;
      border: 0;
    }
    > div {
      text-align: right;
      > button {
        height: 20px;
        line-height: 20px;
        color: #fff;
        padding: 0 15px;
        background: #5579e2;
        font-size: 12px;
        border-radius: 5px;
      }
    }
  }
}

.user_list_tools {
  padding: 5px 20px 10px;
  height: 100%;
  background: #fff;
  .button-div {
    margin-top: 20px;
    text-align: right;
  }
  .label {
    font-size: 16px;
    margin: 20px 0 10px;
  }
}
.input_wrap {
  padding-top: 15px;
  > input {
    border: 1px solid #ddd;
    padding: 0 10px;
    height: 28px;
    line-height: 26px;
    width: 100%;
    font-size: 14px;
    &::placeholder {
      color: #aaa;
      font-size: 14px;
    }
  }
}

.intention_selected {
  border-color: #5579e2;
  color: #5579e2;
}
</style>